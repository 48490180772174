
import { Helmet } from "react-helmet";

interface SEOProps {
  title: string;
  description: string;
  keywords?: string;
  canonicalUrl?: string;
  ogImage?: string;
  structuredData?: Record<string, any>;
  twitterCardType?: "summary" | "summary_large_image";
  noIndex?: boolean;
  type?: "website" | "article" | "product" | "service"; 
  datePublished?: string;
  dateModified?: string;
  author?: string;
  locale?: string;
}

const SEO = ({ 
  title, 
  description, 
  keywords, 
  canonicalUrl, 
  ogImage,
  structuredData,
  twitterCardType = "summary_large_image",
  noIndex = false,
  type = "website",
  datePublished,
  dateModified,
  author = "تیم اینستایار",
  locale = "fa_IR"
}: SEOProps) => {
  const siteUrl = "https://instayar.ir";
  const defaultKeywords = "اینستاگرام، هوش مصنوعی، بازاریابی اینستاگرام، رشد اینستاگرام، اینستایار، محتوای اینستاگرام، تولید محتوا اینستاگرام، افزایش فالوور اینستاگرام، مدیریت پیج اینستاگرام";
  const defaultImage = `${siteUrl}/lovable-uploads/9c4bf010-86f9-489a-a1fc-33aab1bbb19a.png`;
  const siteName = "اینستایار | دستیار هوشمند اینستاگرام";
  
  // Format structured data for JSON-LD based on type
  let defaultStructuredData: Record<string, any> = {};
  
  // Default website schema
  if (type === "website") {
    defaultStructuredData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": siteName,
      "url": canonicalUrl || siteUrl,
      "description": description,
      "inLanguage": locale,
      "potentialAction": {
        "@type": "SearchAction",
        "target": `${siteUrl}/search?q={search_term_string}`,
        "query-input": "required name=search_term_string"
      }
    };
  }
  
  // Article schema
  else if (type === "article") {
    defaultStructuredData = {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": title,
      "description": description,
      "image": ogImage || defaultImage,
      "datePublished": datePublished || new Date().toISOString(),
      "dateModified": dateModified || new Date().toISOString(),
      "author": {
        "@type": "Organization",
        "name": author
      },
      "publisher": {
        "@type": "Organization",
        "name": siteName,
        "logo": {
          "@type": "ImageObject",
          "url": `${siteUrl}/lovable-uploads/06fd915c-5069-461d-a3ee-56e3f0512c1c.png`,
          "width": 112,
          "height": 112
        }
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": canonicalUrl || siteUrl
      },
      "inLanguage": locale
    };
  }
  
  // Service schema
  else if (type === "service") {
    defaultStructuredData = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": title,
      "description": description,
      "provider": {
        "@type": "Organization",
        "name": siteName,
        "image": `${siteUrl}/lovable-uploads/06fd915c-5069-461d-a3ee-56e3f0512c1c.png`
      },
      "serviceType": "سرویس هوش مصنوعی اینستاگرام",
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "IRR"
      }
    };
  }
  
  // Product schema (for pricing page)
  else if (type === "product") {
    defaultStructuredData = {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": title,
      "description": description,
      "operatingSystem": "Web",
      "applicationCategory": "BusinessApplication",
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "IRR"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "ratingCount": "254"
      }
    };
  }
  
  // Format final structured data
  const structuredDataJSON = structuredData 
    ? JSON.stringify(structuredData)
    : JSON.stringify(defaultStructuredData);
  
  // Determine the correct canonical URL - for blog posts, we want the actual URL, not the homepage
  const finalCanonicalUrl = canonicalUrl || siteUrl;
  
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      
      {/* Robots Meta Tags */}
      {noIndex ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      )}
      
      {/* Open Graph Tags */}
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={finalCanonicalUrl} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content={ogImage || defaultImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      
      {datePublished && <meta property="article:published_time" content={datePublished} />}
      {dateModified && <meta property="article:modified_time" content={dateModified} />}
      {author && <meta property="article:author" content={author} />}
      
      {/* Twitter Card Tags */}
      <meta name="twitter:card" content={twitterCardType} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage || defaultImage} />
      
      {/* Canonical URL - now using the proper URL for blog posts */}
      
      
      {/* Structured Data - JSON-LD */}
      <script type="application/ld+json">{structuredDataJSON}</script>
      
      {/* Language and Direction */}
      <html lang="fa" dir="rtl" />
    </Helmet>
  );
};

export default SEO;
